/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Swiper */
@import 'swiper/scss';
@import '~@ionic/angular/css/ionic-swiper.css';


app-root:not(:empty) {
  .ion-page {
    transition: 1s;
  }
}

ion-content {
  --background: var(--backdrop-background);
}

ion-card {
  margin: 0;
  font-size: initial;
  line-height: initial;

  ion-card-title {
    ion-item {
      // compensate for item-inner padding
      padding: 0 0 0 calc(var(--ion-safe-area-right, 0px) + var(--inner-padding-end));
    }
    ion-icon {
      cursor: pointer;
    }
  }

  ion-card-content {
    padding: 0;
    font-size: 14px;
  }

  .card-content-ios,
  .card-content-md {
    padding: 0;
    padding-inline-start: 2vw;
    padding-inline-end: 2vw;
    font-size: 14px;
  }

  ion-item {
    //reduce default
    --padding-start: 10px;
    --padding-end: unset;
    --padding-top: 1vh;
    --padding-bottom: 0.5vh;

    //
    padding-inline-start: 0.5vw;
    padding-inline-end: 0.5vw;

    ion-input {
      font-size: 14px;
    }
  }
}

ion-item-divider {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/* Menu */
ion-menu {
  height: 100vh;
  margin-top: -60px;
  position: relative;

  ion-content {
    ion-list {
      --ion-background-color: var(--backdrop-background);
    }

    ion-icon,
    ion-item,
    ion-input,
    ion-label {
      --color: var(--backdrop-color);
      color: var(--backdrop-color);
    }

    ion-item.item-has-focus > ion-label {
      color: var(--backdrop-color) !important;
    }
  }

  ion-item {
    --ion-background-color: transparent;
    --inner-border-width: 0;
    --border-color: var(--ion-color-primary-contrast);

    margin-left: 0;
    font-size: 15px;
  }

  ion-toggle {
    --background-checked: var(--backdrop-color);
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  ion-modal {
    header,
    main,
    footer {
      max-width: unset;
    }
  }
}

header {
  width: 100vw;
  max-width: 600px;
  height: 60px;
  position: relative;
  background: var(--backdrop-background);
  z-index: 101;
}

main {
  width: 100vw;
  max-width: 600px;
  height: calc(100% - 60px);
  position: relative;
  top: 60px;
  overflow-y: auto;
}

footer {
  width: 100vw;
  max-width: 600px;
  height: calc(100% - 60px);
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--backdrop-background);
  z-index: -101;
}

section {
  padding: 1vh 4vw;
}

.ion-page {
  align-items: center;
}

.category-title {
  padding: 2vh 0 1vh 0;
  line-height: 1.2;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 500;
  color: var(--backdrop-color);
}

.sub-category-title {
  padding: 1vh 0 1vh 0;
  line-height: 1.2;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 500;
  color: var(--backdrop-color-contrast);
}

.icon-title {
  display: flex;
  width: 24px;
  height: 24px;
  font-size: 24px;

  ion-icon {
    pointer-events: none;
  }
}

.button-style {
  position: relative;
  display: block;
  width: 100%;
  height: 2.5em;
  margin: 0.5em auto;
  border-radius: 0.5em;

  outline: none;
  color: var(--ion-color-step-50, var(--ion-color-primary-contrast));
  background: var(--ion-color-primary)
    linear-gradient(
      -15deg,
      rgba(var(--ion-color-primary-rgb), 1),
      rgba(var(--ion-color-primary-contrast-rgb), 0.9)
    );
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  font-size: 18px;

  [size='large'] {
    height: 3em;
    font-size: 20px;
  }

  &::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    content: '';
  }

  &[disabled] {
    cursor: default;
  }

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inner[mode='spaceout'] {
    margin: 0 0.5em;
    justify-content: flex-start;
    align-items: center;

    *:nth-child(n + 2) {
      margin-left: 1em;
    }

    *[break] {
      margin-left: auto;
    }
  }
  .inner ion-icon {
    margin: 0 1em;
    color: inherit;
    --ionicon-stroke-width: 2em;
  }

  @media (any-hover: hover) {
    &:not([disabled]):hover::after {
      content: '';
      background: var(--ion-color-primary);
      opacity: 0.08;
    }
  }

  span {
    align-self: center;
    position: relative;
  }
}

.toast--error,
.toast--joker {
  max-width: 580px;
}

.toast--error {
  --background: var(--ion-color-danger-shade);
  --color: var(--ion-color-danger-contrast);
  border-radius: 1em;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
}

.toast--joker {
  --background: var(--ion-color-primary-shade);
  border-radius: 1em;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
}

.form--error-message {
  padding: 4px;
  display: flex;
  align-items: center;
  color: var(--ion-color-danger);
  font-size: 12px;

  ion-icon {
    color: var(--ion-color-danger);
  }
}
